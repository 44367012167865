'use client';
import config from '@/config';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { type ReactNode } from 'react';

if (typeof window !== 'undefined' && config.analytics.posthog.enabled) {
  posthog.init(config.analytics.posthog.key, {
    api_host: config.analytics.posthog.host,
  });
}

export default function Providers(props: { children: ReactNode }) {
  return (
    <>
      <PostHogProvider client={posthog}>{props.children}</PostHogProvider>
    </>
  );
}
