const env = {
  appName: 'starter',

  environment: process.env.NEXT_PUBLIC_APP_ENV,

  get isDevelopment() {
    return env.environment === 'development';
  },
  get isTest() {
    return env.environment === 'test';
  },
  get isStaging() {
    return env.environment === 'staging';
  },
  get isProduction() {
    return env.environment === 'production';
  },

  get isBrowser() {
    return typeof window !== 'undefined';
  },
  get isLocal() {
    return env.isDevelopment || env.isTest;
  },
};

export default env;
